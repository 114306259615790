<template>
<div class="wizard-nav border-bottom" :class="{'wizard-tabs': isEdit}">
  <div class="wizard-steps p-6 py-lg-6 px-lg-10">
    <div v-for="(item, index) in fields"
         :key="item.key"
         class="wizard-step"
         :class="{'step-active': getState(item.key) === 'current'}"
         :style="{'cursor': isDisable(item.key) ? 'not-allowed' : 'pointer'}"
         :data-wizard-state="getState(item.key)"
         @click="isDisable(item.key) ? '' : onChoose(item.key)"
    >
      <div class="wizard-label">
        <span class="svg-icon svg-icon-info" :class="isEdit ? 'svg-icon-2x' : 'svg-icon-3x'">
          <inline-svg :src="item.icon" />
        </span>
        <h3 class="wizard-title">{{ item.title }}</h3>
        <!--<div class="wizard-desc">-->
        <!--{{ item.subTitle }}-->
        <!--</div>-->
      </div>
      <span v-if="!isEdit" class="svg-icon svg-icon-xl wizard-arrow" :class="{'last': fields.length -1 === index}">
        <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg-->
        <svg
          xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
        >
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <polygon points="0 0 24 0 24 24 0 24" />
            <rect
              fill="#000000"
              opacity="0.3"
              transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
              x="11"
              y="5"
              width="2"
              height="14"
              rx="1"
            />
            <path
              d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045
                C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312
                15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223
                C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
              fill="#000000"
              fill-rule="nonzero"
              transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
            />
          </g>
        </svg>
        <!--end::Svg Icon-->
      </span>
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'NavOrder',
  props: {
    isEdit: {
      type: Boolean,
    },
  },
  data() {
    return {
      fields: [
        {
          key: '',
          title: this.$t('ORDERS.ASIDE.CLIENT'),
          subTitle: this.$t('ORDERS.ASIDE.CLIENT_DESC'),
          icon: '/media/svg/icons/Communication/Add-user.svg',
        },
        {
          key: 'setup',
          title: this.$t('ORDERS.ASIDE.SETUP'),
          subTitle: this.$t('ORDERS.ASIDE.SETUP_DESC'),
          icon: '/media/svg/icons/Shopping/Cart1.svg',
        },
        {
          key: 'files',
          title: this.$t('ORDERS.ASIDE.FILES'),
          subTitle: this.$t('ORDERS.ASIDE.FILES_DESC'),
          icon: '/media/svg/icons/Files/Pictures1.svg',
        },
        {
          key: 'summary',
          title: this.$t('ORDERS.ASIDE.SUMMARY'),
          subTitle: this.$t('ORDERS.ASIDE.SUMMARY_DESC'),
          icon: '/media/svg/icons/General/Clipboard.svg',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isValid: (state) => state.order.isValid,
    }),
    isDisable() {
      return (key) => {
        if (key === 'setup') return !(this.$route.query.client && this.$route.query.order)
        if (key === 'files') return !(this.$route.query.client && this.$route.query.order) || !this.isValid
        if (key === 'summary') return !(this.$route.query.client && this.$route.query.order) || !this.isValid
        return false
      }
    },
    getState() {
      return (key) => {
        if (!this.$route.query.type && !key) return 'current'
        return this.$route.query.type === key ? 'current' : 'pending'
      }
    },
  },
  methods: {
    onChoose(type) {
      if (this.$route.query.type === type) return
      this.$router.replace({
        query: {
          ...this.$route.query,
          type, 
        }, 
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .wizard-tabs{
    .wizard-steps{
      justify-content: left !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      .wizard-step {
        border-bottom: 2px solid transparent;
        margin: 0!important;
      }
      .step-active{
        border-bottom: 2px solid #3699FF;
      }
    }
    .wizard-label {
      display: flex;
      align-items: center;
      padding: 20px 0;
      .wizard-title{
        margin: 0 8px;
        margin-top: 2px !important;
      }
    }
  }
</style>
