<template>
<KTCard class="order" bodyClass="p-0">
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ $t('ORDER.TITLE_NEW') }}</h5>
    </div>
  </template>
  <template #toolbar>
    <div class="example-tools justify-content-center">
      <router-link :to="{ name: 'companyOrdersIndex' }" class="btn btn-secondary font-weight-bold btn-sm">
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg" />
        </span>
        {{ $t('ORDER.BACK_TO_LIST') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <OrderForm />
  </template>
</KTCard>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import KTCard from '../../../content/Card'
import OrderForm from '../../../../components/order/index'

export default {
  name: 'CompanyOrdersCreate',
  components: {
    OrderForm,
    KTCard, 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('ORDER.TITLE_NEW') }])
  },
}
</script>

<style lang="scss">
  .order .bodyClass {
    padding: 0!important;
  }
</style>
