<template>
<div
  class="wizard wizard-1"
  id="kt_wizard"
>
  <!--begin: Wizard Nav -->
  <NavOrder :isEdit="isEdit" />
  <!--end: Wizard Nav -->
  <!--begin: Wizard Body -->
  <b-container fluid class="p-0">
    <b-row class="my-10 px-8">
      <!--begin: Wizard Step 1-->
      <b-col cols="12">
        <ClientOrder
          class="pb-5 w-100"
          data-wizard-type="step-content"
          :data-wizard-state="currentStep === 'client' || currentStep === '' ? 'current' : ''"
        />
        <!--end: Wizard Step 1-->
        <!--begin: Wizard Step 2-->
        <SetupOrder
          class="pb-5"
          data-wizard-type="step-content"
          :data-wizard-state="currentStep === 'setup' ? 'current' : ''"
        />
        <!--end: Wizard Step 2-->
        <!--begin: Wizard Step 3-->
        <FilesOrder
          class="pb-5"
          data-wizard-type="step-content"
          :data-wizard-state="currentStep === 'files' ? 'current' : ''"
        />
        <!--end: Wizard Step 3-->
        <!--begin: Wizard Step 4-->
        <SummaryOrder
          class="pb-5"
          :isEdit="isEdit"
          data-wizard-type="step-content"
          :data-wizard-state="currentStep === 'summary' ? 'current' : ''"
        />
        <!--end: Wizard Step 4-->
      </b-col>
    </b-row>
  </b-container>
  <!--end: Wizard Body -->
</div>
</template>

<script>
import { FETCH_ORDER, SET_ORDER, UPDATE_ORDER } from '@/core/services/store/order.module'
import { mapState } from 'vuex'
import NavOrder from './nav'

const ClientOrder = () => import('./contents/client')
const SetupOrder = () => import('./contents/setup')
const FilesOrder = () => import('./contents/files')
const SummaryOrder = () => import('./contents/summary')
export default {
  name: 'OrderForm',
  components: {
    SummaryOrder,
    FilesOrder,
    SetupOrder,
    NavOrder,
    ClientOrder,
  },
  props: {
    isEdit: {
      type: Boolean,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      order: (state) => state.order.item,
    }),
    currentStep() {
      return this.$route.query.type || ''
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        if (from.query.hasOwnProperty('type') && from.query.type === 'setup') this.$store.dispatch(UPDATE_ORDER, { uuid: this.$route.query.order })
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchData()
  },
  beforeDestroy() {
    if (this.order.uuid) {
      this.$store.dispatch(UPDATE_ORDER, { uuid: this.order.uuid }).then(() => {
        this.$store.commit(SET_ORDER, {})
      })
    }
  },
  methods: {
    fetchData() {
      if (this.$route.query.order) this.$store.dispatch(FETCH_ORDER, this.$route.query.order)
    },
  },
}
</script>

<style lang="scss">
  @import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>
